
<template>
    <div>
        <div class="vx-row">
            <div
                    class="vx-col w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3 mb-base"
            >
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <Last28DaysCard></Last28DaysCard>
                    </div>
                </div>
            </div>

            <div
                    class="vx-col w-full sm:w-full md:w-2/3 lg:w-1/3 xl:w-1/3 mb-base"
            >

                <div class="vx-col w-full mb-base">
                    <FailedPaymentsCard />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Last28DaysCard from './Cards/Last28Days'
    import FailedPaymentsCard from './Cards/FailedPayments'

    export default {
        name: 'Basic',
        components: {
            FailedPaymentsCard,
            Last28DaysCard,
        }
    }
</script>
