<template>
    <vx-card
        title="Failed Payments"
        :subtitle="subTitle"
        :showOverflow="true"
        :edit-action="true"
        @edit="show.edit = !show.edit"
        @search="show.search = !show.search"
        :search="true"
    >
        <div class="mb-5" v-if="failedPayments && failedPayments.length">
            <div
                v-for="(payment, index) in failedPayments"
                :key="index"
                class="flex"
            >
                <div class="vx-col w-1/3">
                    <span class="font-bold"
                        >{{
                            formatDate(
                                payment.failed_date ||
                                    payment.cancelled_date ||
                                    payment.date
                            )
                        }}
                    </span>
                </div>

                <div class="vx-col w-2/3">
                    <span
                        v-if="payment.user"
                        class="link"
                        @click="
                            $router.push({
                                name: 'user',
                                params: { id: payment.user.details.user_id },
                            })
                        "
                    >
                        {{ payment.user.details.full_name }}
                    </span>
                    <span
                        v-else-if="payment.broker"
                        class="link"
                        @click="
                            $router.push({
                                name: 'broker',
                                params: { pk: payment.broker.email },
                            })
                        "
                    >
                        {{ payment.broker.company_name }}
                    </span>
                    <p class="text-secondary text-sm">
                        {{ paymentAmount(payment) }}
                        <span v-if="payment.cancelled">Cancelled</span>
                        <span v-else-if="payment.failed">Failed</span>
                    </p>
                </div>
                <div v-show="payment && show.edit" class="vx-col w-1/4">
                    <div class="flex flex-row-reverse">
                        <vs-button
                            type="border"
                            size="small"
                            color="secondary"
                            v-on:click="dismissAlertConfirm(payment)"
                            icon-pack="feather"
                            icon="icon-minus"
                        ></vs-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row-reverse">
            <div v-if="show.search">
                <label class="vs-input--label">Date Range</label>
                <DatePicker mode="range" v-model="range"></DatePicker>
            </div>
        </div>
    </vx-card>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { textFormat } from '@/mixins/TextFormat'
import { http } from '@/services'

export default {
    name: 'FailedPaymentsCard',
    mixins: [textFormat],
    components: {
        DatePicker,
    },
    watch: {
        range(value) {
            this.$store.dispatch('failedPaymentsFetch', {
                start: this.range.start,
                end: this.range.end,
            })
            this.updated = true
        },
    },
    computed: {
        failedPayments() {
            return this.$store.getters.failedPayments
        },
        subTitle() {
            if (this.updated !== null && this.range.start && this.range.end)
                return `${this.range.start.toDateString()} to ${this.range.end.toDateString()}`
            return 'Last 28 Days'
        },
    },
    data() {
        return {
            updated: null,
            range: {
                start: null,
                end: null,
            },
            show: {
                edit: false,
                search: false,
            },
            selected: null,
        }
    },
    methods: {
        paymentAmount(payment) {
            if (payment.will_amount)
                return `£${payment.will_amount / 100} (Will)`
            if (payment.subscription_amount)
                return `£${payment.subscription_amount / 100} (Subscription)`
            if (payment.printing_amount)
                return `£${payment.printing_amount / 100} (Printing)`
            if (payment.invoice_amount)
                return `£${payment.invoice_amount / 100} (Invoice)`
        },
        dismissAlertConfirm(payment) {
            this.selected = payment.id
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Dismisss`,
                text: 'Dismiss Failed Payment',
                accept: this.dismissAlert,
                acceptText: 'Dismiss',
            })
        },
        dismissAlert() {
            http.delete('failed_payments', { params: { id: this.selected } })
                .then((response) => {
                    this.selected = null
                    this.$store.dispatch('failedPaymentsFetch')
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Appointment deleted',
                        color: 'success',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-trash',
                    })
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        },
    },
}
</script>
