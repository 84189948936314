<template>
    <div>
        <vx-card
            class="show-overflow mb-base"
            v-if="$store.getters.willsThisMonth"
            :title="totalWills + ' Wills and ' + totalUsers + ' Users'"
        >
            <template slot="actions">
                <change-time-duration-dropdown
                    @timeDurationChanged="setDuration"
                    :initialDays="7"
                />
            </template>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vue-apex-charts
                        height="300"
                        :options="options"
                        :series="series"
                    ></vue-apex-charts>
                </div>
            </div>

            <div class="vx-row p-6">
                <div class="vx-col w-full sm:w-1/2">
                    <small>
                        Subscriptions
                        <span
                            v-if="percentSubscriptions"
                            class="text-sm text-grey"
                            >{{ percentSubscriptions }}%</span
                        >
                    </small>
                    <vs-progress
                        :percent="parseFloat(percentSubscriptions)"
                        color="success"
                    />

                    <small>
                        Printing
                        <span v-if="percentPrinting" class="text-sm text-grey"
                            >{{ percentPrinting }}%</span
                        >
                    </small>
                    <vs-progress
                        :percent="parseFloat(percentPrinting)"
                        color="success"
                    />

                    <small>
                        User to Will
                        <span v-if="percentConversion" class="text-sm text-grey"
                            >{{ percentConversion }}%</span
                        >
                    </small>
                    <vs-progress
                        :percent="parseFloat(percentConversion)"
                        color="success"
                    />
                </div>

                <div class="vx-col w-full sm:w-1/2">
                    <small>
                        Expecting Child
                        <span v-if="percentExpecting" class="text-sm text-grey"
                            >{{ percentExpecting }}%</span
                        >
                    </small>
                    <vs-progress
                        :percent="parseFloat(percentExpecting)"
                        color="primary"
                    />

                    <small>
                        Handover Wills
                        <span v-if="percentHandover" class="text-sm text-grey"
                            >{{ percentHandover }}%</span
                        >
                    </small>
                    <vs-progress
                        :percent="parseFloat(percentHandover)"
                        color="primary"
                    />
                    <small>
                        Moving Home
                        <span v-if="percentMovingHome" class="text-sm text-grey"
                            >{{ percentMovingHome }}%</span
                        >
                    </small>
                    <vs-progress
                        :percent="parseFloat(percentMovingHome)"
                        color="primary"
                    />
                    <small>
                        Pro. Executors
                        <span v-if="percentExecutors" class="text-sm text-grey"
                            >{{ percentExecutors }}%</span
                        >
                    </small>
                    <vs-progress
                        :percent="parseFloat(percentExecutors)"
                        color="primary"
                    />
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'

import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'Last28DaysCard',
    mixins: [textFormat],
    components: {
        VueApexCharts,
        ChangeTimeDurationDropdown,
    },
    computed: {
        dataWills() {
            return this.$store.getters.willsThisMonth
        },
        percentPrinting() {
            if (
                this.dataWills &&
                this.dataWills.printing.total_printing &&
                this.dataWills.printing.total_wills
            )
                return parseFloat(
                    (this.dataWills.printing.total_printing /
                        this.dataWills.printing.total_wills) *
                        100
                ).toFixed(1)
            return 0
        },
        percentSubscriptions() {
            if (
                this.dataWills &&
                this.dataWills.subscriptions.total_subscriptions
            )
                return parseFloat(
                    (this.dataWills.subscriptions.total_subscriptions /
                        this.dataWills.subscriptions.total_wills) *
                        100
                ).toFixed(1)
            return 0
        },
        percentExecutors() {
            if (this.dataWills && this.dataWills.executors)
                return parseFloat(
                    ((this.dataWills.executors.professional_plus_family +
                        this.dataWills.executors.professional_only) /
                        this.dataWills.executors.total_wills) *
                        100
                ).toFixed(1)
            return 0
        },
        percentConversion() {
            if (this.dataWills.conversion) {
                return parseFloat(
                    (this.dataWills.conversion.users_with_wills /
                        this.dataWills.conversion.total_users) *
                        100
                ).toFixed(1)
            }
            return 0
        },
        percentMovingHome() {
            if (this.dataWills.moving_home) {
                return parseFloat(
                    (this.dataWills.moving_home.users_moving_home /
                        this.dataWills.moving_home.total_users) *
                        100
                ).toFixed(2)
            }
            return 0
        },
        percentExpecting() {
            if (this.dataWills.expecting) {
                return parseFloat(
                    (this.dataWills.expecting.users_expecting /
                        this.dataWills.expecting.total_users) *
                        100
                ).toFixed(2)
            }
            return 0
        },
        percentHandover() {
            if (this.dataWills.handover) {
                return parseFloat(
                    (this.dataWills.handover.users_handover /
                        this.dataWills.handover.total_users) *
                        100
                ).toFixed(2)
            }
            return 0
        },
        series() {
            return [
                {
                    name: 'Wills',
                    type: 'bar',
                    data: this.$store.getters.willsThisMonth.wills.map(
                        (value) => {
                            return {
                                x: this.formatDate(new Date(value.paid_date)),
                                y: value.count,
                            }
                        }
                    ),
                },
                {
                    name: 'Users',
                    type: 'area',
                    data: this.$store.getters.willsThisMonth.users.map(
                        (value) => {
                            return {
                                x: this.formatDate(new Date(value.date_joined)),
                                y: value.count,
                            }
                        }
                    ),
                },
                /*
                {
                    name: 'Invitations',
                    type: 'line',
                    data: this.$store.getters.willsThisMonth.invitations.map(
                        (value) => {
                            return {
                                x: this.formatDate(new Date(value.date_added)),
                                y: value.count,
                            }
                        }
                    ),
                },

 */
            ]
        },
        // seriesLogs() {
        //     return [
        //         {
        //             name: 'Client Invitations',
        //             type: 'bar',
        //             data: this.$store.getters.willsThisMonth.invitations.map(
        //                 (value) => {
        //                     return {
        //                         x: this.formatDate(new Date(value.date_added)),
        //                         y: value.count,
        //                     }
        //                 }
        //             ),
        //         },
        //         {
        //             name: 'Client Reminders 1',
        //             type: 'bar',
        //             data: this.$store.getters.willsThisMonth.reminders_1.map(
        //                 (value) => {
        //                     return {
        //                         x: this.formatDate(new Date(value.date_added)),
        //                         y: value.count,
        //                     }
        //                 }
        //             ),
        //         },
        //         {
        //             name: 'Client Reminders 2',
        //             type: 'bar',
        //             data: this.$store.getters.willsThisMonth.reminders_2.map(
        //                 (value) => {
        //                     return {
        //                         x: this.formatDate(new Date(value.date_added)),
        //                         y: value.count,
        //                     }
        //                 }
        //             ),
        //         },
        //         {
        //             name: 'Client Reminders 3',
        //             type: 'bar',
        //             data: this.$store.getters.willsThisMonth.reminders_3.map(
        //                 (value) => {
        //                     return {
        //                         x: this.formatDate(new Date(value.date_added)),
        //                         y: value.count,
        //                     }
        //                 }
        //             ),
        //         },
        //         {
        //             name: 'User Day 1 Reminder',
        //             type: 'bar',
        //             data: this.$store.getters.willsThisMonth.users_day_1.map(
        //                 (value) => {
        //                     return {
        //                         x: this.formatDate(new Date(value.date_added)),
        //                         y: value.count,
        //                     }
        //                 }
        //             ),
        //         },
        //         {
        //             name: 'User Week 1 Reminder',
        //             type: 'bar',
        //             data: this.$store.getters.willsThisMonth.users_week_1.map(
        //                 (value) => {
        //                     return {
        //                         x: this.formatDate(new Date(value.date_added)),
        //                         y: value.count,
        //                     }
        //                 }
        //             ),
        //         },
        //         {
        //             name: 'User Month 1 Reminder',
        //             type: 'bar',
        //             data: this.$store.getters.willsThisMonth.users_month_1.map(
        //                 (value) => {
        //                     return {
        //                         x: this.formatDate(new Date(value.date_added)),
        //                         y: value.count,
        //                     }
        //                 }
        //             ),
        //         },
        //         {
        //             name: 'User Month 6 Reminder',
        //             type: 'bar',
        //             data: this.$store.getters.willsThisMonth.users_month_6.map(
        //                 (value) => {
        //                     return {
        //                         x: this.formatDate(new Date(value.date_added)),
        //                         y: value.count,
        //                     }
        //                 }
        //             ),
        //         },
        //     ]
        // },

        options() {
            return {
                chart: {
                    sparkline: {
                        enabled: true,
                    },
                    stacked: false,
                },

                toolbar: {
                    show: true,
                },
                dropShadow: {
                    enabled: true,
                    top: 5,
                    left: 0,
                    blue: 4,
                    opacity: 0.1,
                },
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0,
                    },
                    borderColor: '#345a7a',
                },

                states: {
                    hover: {
                        filter: 'none',
                    },
                },
                colors: ['#4f9b7e', '#CED4DC'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '45%',
                        distribution: true,
                        endingShape: 'rounded',
                        dataLabels: {
                            position: 'top',
                        },
                    },
                },
                stroke: {
                    width: [0, 0, 1],
                    curve: 'smooth',
                },
                fill: {
                    opacity: [0.85, 0.25, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: 'vertical',
                        opacityFrom: 1,
                        opacityTo: 0.95,
                        stops: [0, 100, 100, 100],
                    },
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: true,
                    },
                },
            }
        },

        // optionsLogs() {
        //     return {
        //         chart: {
        //             sparkline: {
        //                 enabled: true,
        //             },
        //
        //             stacked: true,
        //         },
        //
        //         toolbar: {
        //             show: true,
        //         },
        //         dropShadow: {
        //             enabled: true,
        //             top: 5,
        //             left: 0,
        //             blue: 4,
        //             opacity: 0.1,
        //         },
        //         grid: {
        //             show: false,
        //             padding: {
        //                 left: 0,
        //                 right: 0,
        //             },
        //             borderColor: '#345a7a',
        //         },
        //
        //         states: {
        //             hover: {
        //                 filter: 'none',
        //             },
        //         },
        //         colors: [
        //             '#4f9b7e',
        //             '#4f9b7e',
        //             '#4f9b7e',
        //             '#4f9b7e',
        //             '#345a7a',
        //             '#345a7a',
        //             '#345a7a',
        //             '#345a7a',
        //         ],
        //         plotOptions: {
        //             bar: {
        //                 horizontal: false,
        //                 columnWidth: '45%',
        //                 distribution: true,
        //                 dataLabels: {
        //                     position: 'top',
        //                 },
        //             },
        //         },
        //         stroke: {
        //             width: [0, 0, 1],
        //             curve: 'smooth',
        //         },
        //         fill: {
        //             opacity: [0.85, 0.25, 1],
        //             gradient: {
        //                 inverseColors: false,
        //                 shade: 'light',
        //                 type: 'vertical',
        //                 opacityFrom: 1,
        //                 opacityTo: 0.95,
        //                 stops: [0, 100, 100, 100],
        //             },
        //         },
        //         tooltip: {
        //             enabled: true,
        //             x: {
        //                 show: true,
        //             },
        //         },
        //     }
        // },

        totalWills() {
            var total = 0
            for (var i = this.duration; i < this.dataWills.wills.length; i++) {
                total += this.dataWills.wills[i].count
            }
            return total
        },
        totalUsers() {
            var total = 0
            for (var i = this.duration; i < this.dataWills.users.length; i++) {
                total += this.dataWills.users[i].count
            }
            return total
        },
    },
    data() {
        return {
            duration: 0,
            days: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
        }
    },
    methods: {
        setDuration(value) {
            if (value === '365') this.$store.dispatch('willsThisMonth', 365)
            if (value === '182') this.$store.dispatch('willsThisMonth', 182)
            if (value === '28') this.$store.dispatch('willsThisMonth', 28)
            if (value === '14') this.$store.dispatch('willsThisMonth', 14)
            if (value === '7') this.$store.dispatch('willsThisMonth', 7)
        },
        formatDate(date) {
            return (
                this.days[date.getDay()] +
                ' ' +
                date.getDate() +
                '/' +
                (date.getMonth() + 1) +
                '/' +
                date.getFullYear()
            )
        },
    },
}
</script>
